<template lang="pug">
  .about
    Glitch
    .content.pl-5.pr-5.pb-5.pt-4.animate__animated.animate__fadeInUp
      //- div(v-html="content")
      h1 {{slogan}}
      h1 {{title}}
      p(v-html="content[0]")
      div.pb-5
        .img-block.ml-2.mr-4.mt-3
          MediaBox(
            :images="image"
            :showCaption="false"
            :offset="{x: '-10px', y: '30px'}"
            :width="500" :height="350"
            :scale="1.03"
            )
          //- img(:src="require('@/assets/images/about.png')")
          //- h1.mt-3 FROM HPC to AI
        p(v-html="content[1]")
    HUD
</template>

<script>
import HUD from "@/components/HUD.vue";
import MediaBox from "@/components/MediaBox";
import Glitch from "@/components/Glitch";
const axios = require('axios')

export default {
  name: "About",
  data(){
    return {
      slogan: "From HPC to AI",
      title: "從高速計算到人工智慧",
      content: ["財團法人國家實驗研究院國家高速網路與計算中心(簡稱國網中心)，於1991年成立，擁有全台灣唯一共用之大型計算平台及學術研究網路設施，肩負計算、儲存、網路、平台整合的前瞻雲端技術先導角色，提供國內各界高速計算、高品質網路、高效能儲存、大資料分析及科學工程模擬等雲端整合服務。<br><br>國網中心的願景是在於「成為國際級高速計算中心，促成科學發現與技術創新」。自成立以來，國網中心致力提升台灣高速計算與網路基礎設施能量，規劃執行先導性高速計算、雲端運算、大資料計算之方法與應用研究，以專業技術與平台服務學研產官各界，培育無數相關領域之高速計算人才。",
                "為有效支援台灣科技研究，國網中心建構科技研發平台，支援國內外研發團隊高速計算與大資料庫發展工作，範圍涵蓋工程與科學、環境與災防、生物醫學及數位文創等應用領域，打造成為一流的國際級高速計算中心。<br> 2017年起國網中心承接「前瞻基礎建設之建構雲端服務及大數據運算平台計畫」，建構國家級AI人工智慧研發基礎設施，以促進國內新興科技與產業發展，落實新世代關鍵技術紮根。<br><br>為實現對未來AI智慧生活的想像，國網中心亦延續聚焦環境與災防、生物醫學、科學與工程、數位文創等雲端應用領域，進行深度學習、人工智慧等前瞻創新應用技術與服務研發。期透過新一代的科技革新，協助相關應用領域產業轉型及躍升，再度引領台灣加速邁入AI智能化科技創新世代的「有感智慧生活」。"],
      image: [{
        url: require('@/assets/images/about.png')
      }]
    }
  },
  created(){
    axios.get(process.env.VUE_APP_API_URL + '/about').then(res=>{
      if(!res.data) return

      this.content[0] = res.data.paragraph1.replace(/\n/g,'<br>')
      this.content[1] = res.data.paragraph2.replace(/\n/g,'<br>')
      this.slogan = res.data.slogan
      this.title = res.data.title
      this.image = [{url: process.env.VUE_APP_API_URL + res.data.image.url}]
    })
  },
  components:{HUD,MediaBox, Glitch}
};
</script>

<style lang="sass">
.about
  width: 100vw
  height: 100vh
  background-color: black
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden
  
  .content
    max-width: 65vw
    max-height: 85vh
    margin-top: -100px
    overflow: hidden scroll
    color: white
    border: solid 0.5px white
    text-align: left
    position: relative
    float: left
    background-color: black
    position: relative
    z-index: 500
    h1
      font-weight: bold
    .img-block
      float: left
      h1
        font-weight: normal
</style>

